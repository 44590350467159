import React, { Component } from 'react';
import Product from './Product';
import ProductFill from "./ProductFill";

class Products extends Component
{
  render()
  {
      let products = null;
      if(this.props.products == null || this.props.products.length == 0)
      {
          products = <ProductFill/>;
      }
      else {

          let date = new Date();
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          let day = date.getDate();

          let checkout = this.props.checkout;
          products = this.props.products
              // TODO reactivate with tag
              // .filter(product =>
              // {
              //     // year
              //     if(parseInt(product.publishedAt.substr(0,4)) < year) return true;
              //     else if(parseInt(product.publishedAt.substr(0,4)) > year) return false;
              //
              //     // console.log("year is the same: " + parseInt(product.publishedAt.substr(0,4)) + " / " + year + " - " + product.title);
              //
              //     // month
              //     if(parseInt(product.publishedAt.substr(5,2)) < month) return true;
              //     else if(parseInt(product.publishedAt.substr(5,2)) > month) return false;
              //
              //     // console.log("month is the same: " + parseInt(product.publishedAt.substr(5,2)) + " / " + month + " - " + product.title);
              //
              //     // day
              //     if(parseInt(product.publishedAt.substr(8,2)) <= day) return true;
              //     else return false;
              // })
              .map((product) => {
                  return (
                      <Product
                          addVariantToCart={this.props.addVariantToCart}
                          key={product.id.toString()}
                          product={product}
                          checkout={checkout}
                          client={this.props.client}
                          updateQuantityInCart={this.props.updateQuantityInCart}
                      />
                  );
              });
      }

    return (
        <section className="container padding-top padding-bottom">
            {/*<div style={{display:"flex"}}>*/}
            {/*    <div style={{marginLeft:"auto", marginRight:"auto"}}><h2><a href={"/summer-ale"}>Summer Ale Aktion</a> Buy 1 Get 1 Free (24er)</h2></div>*/}
            {/*</div>*/}
          <div className="row">
            {products == 0 ? <ProductFill/> : products}
          </div>
          {/*<a href="#" className="load-more-btn space-top">Load More <sup>25</sup></a>*/}
        </section>
    );
  }
}

export default Products;
